var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TTDialog", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_vm._v(_vm._s(_vm.selectedOperation?.name))]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.selectedOperation.showTotals
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "d-flex", attrs: { cols: "6" } },
                      [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            'Total to be marked "' +
                              _vm._s(_vm.selectedOperation?.label) +
                              '":'
                          ),
                        ]),
                        _c("v-text-field", {
                          staticStyle: {
                            "margin-top": "-20px",
                            "margin-left": "15px",
                          },
                          attrs: {
                            readonly: "",
                            "prepend-inner-icon": "mdi-currency-usd",
                          },
                          model: {
                            value: _vm.selectedInvoicesTotal,
                            callback: function ($$v) {
                              _vm.selectedInvoicesTotal = $$v
                            },
                            expression: "selectedInvoicesTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c("v-data-table", {
                  staticClass: "elevation-1",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.operationData.invoices,
                    "items-per-page": _vm.pageSetup.pageSize,
                    "footer-props": {
                      "items-per-page-options": [8],
                    },
                    "show-select": "",
                    loading: _vm.isTableLoading,
                  },
                  on: {
                    "item-selected": _vm.setSelection,
                    "toggle-select-all": _vm.selectionChangedViaCheckbox,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header.data-table-select",
                      fn: function ({ on, props }) {
                        return [
                          !_vm.isSingleSelect
                            ? _c(
                                "v-simple-checkbox",
                                _vm._g(
                                  _vm._b({}, "v-simple-checkbox", props, false),
                                  on
                                )
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.invoicePayments",
                      fn: function ({ header, item }) {
                        return [_vm._v(" " + _vm._s(header.render(item)) + " ")]
                      },
                    },
                    {
                      key: "item.status",
                      fn: function ({ header, item }) {
                        return [
                          _c(
                            "v-chip",
                            {
                              staticClass: "font-weight-bold",
                              attrs: {
                                color: _vm.getStatus(header, item).color,
                                small: "",
                                label: "",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getStatus(header, item).text) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.operationData.selected,
                    callback: function ($$v) {
                      _vm.$set(_vm.operationData, "selected", $$v)
                    },
                    expression: "operationData.selected",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-end" },
              [
                _c("v-pagination", {
                  attrs: {
                    length: Math.ceil(
                      Number(_vm.pageSetup.length) /
                        Number(_vm.pageSetup.pageSize)
                    ),
                  },
                  on: { input: _vm.pageChanged },
                  model: {
                    value: _vm.pageSetup.currentPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.pageSetup, "currentPage", $$v)
                    },
                    expression: "pageSetup.currentPage",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                attrs: { loading: _vm.isProcessing, color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.process()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.selectedOperation?.name) + " Selected")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v
      },
      expression: "dialog",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }