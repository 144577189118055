const computeMileageTotal = ({ mileage, pickUp, mileageRate, additionalMileageCost }) => {
  const safeMileage = parseFloat(mileage) || 0;
  const safePickUp = parseFloat(pickUp) || 0;
  const safeMileageRate = parseFloat(mileageRate) || 0;
  const safeAdditionalCost = parseFloat(additionalMileageCost) || 0;

  const totalBaseCost = safeMileageRate * safeMileage;
  const totalAdditionalCost = safeAdditionalCost * (safeMileage + safePickUp);
  const pickUpCost = safePickUp * safeMileageRate;

  const totalMileageCost = totalBaseCost + totalAdditionalCost + pickUpCost;

  return totalMileageCost;
};

const computeFuelRateTotal = ({ fuelRate, milesPerGallon, fuelUsed, pickUp }) => {
  const safeFuelRate = parseFloat(fuelRate) || 0;
  const safeMilesPerGallon = parseFloat(milesPerGallon) || 0;
  const safeFuelUsed = parseFloat(fuelUsed) || 0;
  const safePickUp = parseFloat(pickUp) || 0;

  const pickUpFuel = safePickUp / safeMilesPerGallon;

  const totalFuelUsed = safeFuelUsed + pickUpFuel;

  return totalFuelUsed * safeFuelRate;
};

const computeTotal = (form) => {
  let total = 0;

  if (form.isMileageRate) total += computeMileageTotal(form);
  if (form.isFuelRate) total += computeFuelRateTotal(form);

  return total;
};

module.exports = {
  computeTotal,
};
