var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("Table", {
        attrs: {
          headers: _vm.headers,
          rows: _vm.rows,
          itemsPerPage: -1,
          color: _vm.colors.lightPink,
          sortable: "",
          loading: _vm.invoiceIsFetchingHistory,
          menuOptions: _vm.menuOptions,
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                !_vm.invoiceReadOnly
                  ? _c(
                      "v-btn",
                      { on: { click: _vm.showCommentForm } },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-plus"),
                        ]),
                        _vm._v(" Add Comment "),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.formDialog.visible
        ? _c("InvoiceDetailsCommentDialog", {
            attrs: {
              visible: _vm.formDialog.visible,
              invoiceId: _vm.invoiceId,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.formDialog, "visible", $event)
              },
            },
          })
        : _vm._e(),
      _c("CustomFormFieldsDisplay", {
        staticClass: "pt-4",
        attrs: {
          "is-readonly": _vm.invoiceReadOnly,
          section: "History",
          parentId: _vm.invoiceId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }