<template>
  <Dialog :value="visible" @input="handleClose">
    <template #header> Edit Travel Cost Details </template>
    <template #body>
      <v-form @submit="saveTravelCost" ref="formEditTravelCost" v-model="form.valid">
        <v-row dense>
          <v-col cols="12" sm="6" v-if="fields.some((field) => field.value === 'date' && !field.disabled)">
            <DatePicker
              :rules="[
                rules.required,
                rules.date,
                rules.minDate(dateValidationDetails.minimumDate, dateValidationDetails.dateStringMessage),
                rules.maxDate(dateValidationDetails.maximumDate, dateValidationDetails.dateStringMessage),
                rules.allowedDates(travelCostDates),
              ]"
              v-bind="inputProps"
              label="Date"
              v-model="form.date"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'pickUp' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.decimal]"
              v-bind="inputProps"
              label="Pick Up (mi)"
              v-model="form.pickUp"
              type="number"
              :hint="pickUpMilesHint"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'startOdometer' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.numeric, rules.min(0), rules.max(form.endOdometer), rules.decimal]"
              v-bind="inputProps"
              label="Start Odometer"
              v-model="form.startOdometer"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'endOdometer' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.numeric, rules.min(form.startOdometer), rules.decimal]"
              v-bind="inputProps"
              label="End Odometer"
              v-model="form.endOdometer"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'milesPerGallon' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.numeric, rules.min(1), rules.decimal]"
              v-bind="inputProps"
              label="Miles per Gallon"
              v-model="form.milesPerGallon"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'fuelUsed' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.numeric, rules.min(0.01), rules.decimal]"
              v-bind="inputProps"
              label="Fuel (gal)"
              v-model="form.fuelUsed"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'fuelRate' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.numeric, rules.min(0), rules.decimal]"
              v-bind="inputProps"
              label="Fuel Rate"
              v-model="form.fuelRate"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'mileage' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.decimal]"
              v-bind="{
                ...inputProps,
                readonly: hasOdometerReading(form),
                disabled: hasOdometerReading(form),
              }"
              label="Mileage"
              v-model="form.mileage"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'mileageRate' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.decimal]"
              v-bind="inputProps"
              label="Mileage Rate"
              v-model="form.mileageRate"
              type="number"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'mileageRate' && !field.disabled)">
            <v-text-field
              :rules="[rules.required, rules.decimal]"
              v-bind="inputProps"
              label="Additional Cost Per Mile"
              v-model="form.additionalMileageCost"
              type="number"
              :readonly="!canEditAdditionalCostPerMile"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="fields.some((field) => field.value === 'total' && !field.disabled)">
            <v-text-field
              v-bind="{ ...inputProps, readonly: true, disabled: true }"
              label="Total Cost"
              :value="totalCost"
              type="number"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn :disabled="!form.valid" :loading="invoiceIsFetchingTravelCost" color="success" @click="saveTravelCost">
        <v-icon left>mdi-content-save</v-icon>
        Save
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { inputProps } from '@/util';
import { computeTotal } from '@common/invoice/travel-cost-total';
import DatePicker from '@/components/DatePicker';
import { Dialog } from '@/components/shared';
import { mapGetters } from 'vuex';
import { required, numeric, max, min, date, minDate, maxDate, allowedDates, decimalPlaces } from '@/util/rules';
import { mapActions } from 'vuex';

export default {
  props: {
    details: { type: Object, default: () => ({}) },
    visible: { type: Boolean, default: false },
    fields: { type: Array, default: () => [] },
    rates: { type: Array, default: () => [] },
    setFormMileage: { type: Function, required: true },
    validateForm: { type: Function, required: true },
    save: { type: Function, required: true },
    hasOdometerReading: { type: Function, required: true },
    pickUpMilesHint: { type: String, default: () => {} },
    dateValidationDetails: { type: Object },
    canEditAdditionalCostPerMile: { type: Boolean, default: false },
  },
  components: { DatePicker, Dialog },
  watch: {
    'form.startOdometer'() {
      this.handleOdometerChange();
    },
    'form.endOdometer'() {
      this.handleOdometerChange();
    },
  },
  data() {
    return {
      rules: {
        numeric,
        required,
        max,
        min,
        date,
        minDate,
        maxDate,
        allowedDates,
        decimal: decimalPlaces(2),
      },
      dialog: false,
      invoiceId: null,
      initialDate: null,
      form: {
        id: null,
        date: null,
        pickUp: null,
        startOdometer: null,
        endOdometer: null,
        milesPerGallon: null,
        fuelUsed: null,
        fuelRate: null,
        mileageRate: null,
        valid: false,
      },
      inputProps,
    };
  },
  computed: {
    ...mapGetters('invoice', ['invoiceIsFetchingTravelCost', 'invoiceDetailsTravelCost']),
    ...mapGetters('config', ['invoiceConfig', 'permissions']),
    ...mapGetters('user', ['isAdmin', 'me']),
    travelCostDates() {
      if (!this.invoiceDetailsTravelCost?.invoiceTravel?.length) return [];
      const dates = this.invoiceDetailsTravelCost?.invoiceTravel?.map((cost) => cost.date);
      return dates.filter((dateString) => {
        const initialDate = new Date(this.initialDate).setHours(0, 0, 0, 0);
        const date = new Date(dateString).setHours(0, 0, 0, 0);

        return date != initialDate;
      });
    },
    totalCost() {
      return computeTotal({
        rates: this.rates,
        pickUp: this.form.pickUp,
        startOdometer: this.form.startOdometer,
        endOdometer: this.form.endOdometer,
        milesPerGallon: this.form.milesPerGallon,
        fuelUsed: this.form.fuelUsed,
        fuelRate: this.form.fuelRate,
        mileage: this.form.mileage,
        mileageRate: this.form.mileageRate,
      }).toFixed(2);
    },
  },
  methods: {
    ...mapActions('invoice', ['saveInvoiceTravelCost']),
    computeTotal,
    handleOdometerChange() {
      if (this.form.startOdometer && this.form.endOdometer) this.setFormMileage(this.form);

      this.validateForm(this.$refs.formEditTravelCost);
    },
    saveTravelCost() {
      this.save({
        validate: () => this.validateForm(this.$refs.formEditTravelCost),
        success: this.handleClose,
        form: this.form,
      });
    },
    handleClose() {
      this.$emit('update:visible', false);
    },
    setFormValues() {
      this.form.id = this.details.travelCost.id;
      this.form.pickUp = this.details.travelCost.pickUp;
      this.form.date = this.details.travelCost.date;
      this.form.startOdometer = this.details.travelCost.startOdometer;
      this.form.endOdometer = this.details.travelCost.endOdometer;
      this.form.milesPerGallon = this.details.travelCost.milesPerGallon;
      this.form.fuelUsed = this.details.travelCost.fuelUsed;
      this.form.fuelRate = this.details.travelCost.fuelRate;
      this.form.mileage = this.details.travelCost.mileage;
      this.form.mileageRate = this.details.travelCost.mileageRate;
      this.form.additionalMileageCost = this.details.travelCost.additionalMileageCost;
    },
  },
  mounted() {
    this.invoiceId = this.details.invoiceId;
    this.initialDate = this.details.travelCost.date;
    this.dialog = this.visible;
    this.setFormValues();
  },
};
</script>
