var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      { staticClass: "text-capitalize", attrs: { text: "" } },
                      on
                    ),
                    [
                      _vm._v(" Sort: "),
                      _c("b", [_vm._v(_vm._s(_vm.sortDescription))]),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(" " + _vm._s(_vm.sortIcon) + " "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.invoiceSortOptions, function (option, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.sortInvoice(option)
                    },
                  },
                },
                [
                  _c("v-list-item-title", [_vm._v(_vm._s(option.text))]),
                  _vm.isCurrentSort(option)
                    ? _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(_vm.sortIcon) + " "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }