var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm._l(_vm.staffTypes, function (staffType) {
        return _c("section", { key: staffType }, [
          _c(
            "div",
            { staticClass: "py-3" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-select",
                        _vm._b(
                          {
                            attrs: {
                              disabled: !_vm.canEditStaff({ staffType }),
                              items: _vm.staffs,
                              label:
                                staffType === "Assistant"
                                  ? _vm.driverAssistantLabel
                                  : staffType,
                              rules: [_vm.rules.required],
                              clearable: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.saveAssignmentDriverAssistant({
                                  staffType,
                                })
                              },
                            },
                            model: {
                              value: _vm.staff[staffType],
                              callback: function ($$v) {
                                _vm.$set(_vm.staff, staffType, $$v)
                              },
                              expression: "staff[staffType]",
                            },
                          },
                          "v-select",
                          _vm.inputProps,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c("v-col", [
                    _vm.invoiceDetailsStaffCost
                      ? _c(
                          "div",
                          { staticClass: "grey--text text-caption" },
                          [
                            _vm.retirementMap[staffType]
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v(_vm._s(staffType) + " ID"),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.invoiceDetailsStaffCost[
                                            `${staffType.toLowerCase()}Id`
                                          ] || 0
                                        )
                                      ),
                                    ]),
                                    _vm.invoiceConfig
                                      .calculateBenefitsWithDriverRate
                                      ? _c("v-col", { attrs: { cols: "4" } }, [
                                          _vm._v("Retirement"),
                                        ])
                                      : _vm._e(),
                                    _vm.invoiceConfig
                                      .calculateBenefitsWithDriverRate
                                      ? _c("v-col", { attrs: { cols: "2" } }, [
                                          _vm._v(
                                            " $" +
                                              _vm._s(
                                                Number(
                                                  _vm.invoiceStaffBenefits[
                                                    staffType.toLowerCase()
                                                  ].retirement
                                                ).toFixed(2)
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.invoiceConfig.calculateBenefitsWithDriverRate
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v("SS"),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        " $" +
                                          _vm._s(
                                            Number(
                                              _vm.invoiceStaffBenefits[
                                                staffType.toLowerCase()
                                              ].ss
                                            ).toFixed(2)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v("Workers Comp"),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        " $" +
                                          _vm._s(
                                            Number(
                                              _vm.invoiceStaffBenefits[
                                                staffType.toLowerCase()
                                              ].workerComp
                                            ).toFixed(2)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.invoiceConfig.calculateBenefitsWithDriverRate
                              ? _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "4" } }, [
                                      _vm._v("Medicare"),
                                    ]),
                                    _c("v-col", { attrs: { cols: "2" } }, [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            Number(
                                              _vm.invoiceStaffBenefits[
                                                staffType.toLowerCase()
                                              ].medical
                                            ).toFixed(2)
                                          )
                                      ),
                                    ]),
                                    _c("v-col"),
                                    _c("v-col"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("v-col"),
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: `staffCostForm${staffType}`,
                  refInFor: true,
                  staticClass: "my-4",
                  model: {
                    value: _vm.staffForm.valid,
                    callback: function ($$v) {
                      _vm.$set(_vm.staffForm, "valid", $$v)
                    },
                    expression: "staffForm.valid",
                  },
                },
                [
                  _c("Table", {
                    attrs: {
                      menuOptions: _vm.menuOptions,
                      itemsPerPage: -1,
                      color: _vm.colors.lightGreen,
                      sortable: "",
                      headers: _vm.updatedHeaders,
                      rows: _vm.getRows(staffType),
                      loading: _vm.invoiceIsFetchingStaffCost,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "footer",
                          fn: function () {
                            return [
                              _c("tfoot", [
                                _vm.showAddNewRow === staffType
                                  ? _c("tr", { staticClass: "height-adjust" }, [
                                      _c(
                                        "th",
                                        [
                                          _c(
                                            "DatePicker",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.minDate(
                                                      _vm.minimumDate,
                                                      _vm.rules
                                                        .dateStringMessage
                                                    ),
                                                    _vm.rules.maxDate(
                                                      _vm.maximumDate,
                                                      _vm.rules
                                                        .dateStringMessage
                                                    ),
                                                  ],
                                                  label: "Date",
                                                },
                                                model: {
                                                  value: _vm.staffForm.date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staffForm,
                                                      "date",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "staffForm.date",
                                                },
                                              },
                                              "DatePicker",
                                              _vm.tableInputProps,
                                              false
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.showStartEndTime
                                        ? _c(
                                            "th",
                                            [
                                              _c(
                                                "TimePicker",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "Start Time",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.updateHours(
                                                          _vm.staffForm
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.staffForm.startTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.staffForm,
                                                          "startTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "staffForm.startTime",
                                                    },
                                                  },
                                                  "TimePicker",
                                                  _vm.tableInputProps,
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showStartEndTime
                                        ? _c(
                                            "th",
                                            [
                                              _c(
                                                "TimePicker",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "End Time",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.updateHours(
                                                          _vm.staffForm
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.staffForm.endTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.staffForm,
                                                          "endTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "staffForm.endTime",
                                                    },
                                                  },
                                                  "TimePicker",
                                                  _vm.tableInputProps,
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "th",
                                        [
                                          _c(
                                            "v-text-field",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.decimal,
                                                  ],
                                                  label: "Hours",
                                                },
                                                model: {
                                                  value: _vm.staffForm.hours,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staffForm,
                                                      "hours",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "staffForm.hours",
                                                },
                                              },
                                              "v-text-field",
                                              _vm.tableInputProps,
                                              false
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.showDistrictRateType
                                        ? _c(
                                            "th",
                                            [
                                              _c(
                                                "v-select",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label:
                                                        "District Wide Rate Type",
                                                      rules: [
                                                        _vm.rules.required,
                                                      ],
                                                      items: _vm.enumToArray(
                                                        _vm.districtRateTypes
                                                      ),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.setHourlyRate(
                                                          {
                                                            form: _vm.staffForm,
                                                            staff:
                                                              _vm.currentStaff[
                                                                staffType
                                                              ],
                                                            staffType,
                                                          }
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.staffForm
                                                          .districtRateType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.staffForm,
                                                          "districtRateType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "staffForm.districtRateType",
                                                    },
                                                  },
                                                  "v-select",
                                                  _vm.tableInputProps,
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "th",
                                        [
                                          _c(
                                            "v-select",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Rate Type",
                                                  rules: [_vm.rules.required],
                                                  items: _vm.enumToArray(
                                                    _vm.staffCostMap.rateType
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.setHourlyRate({
                                                      form: _vm.staffForm,
                                                      staff:
                                                        _vm.currentStaff[
                                                          staffType
                                                        ],
                                                      staffType,
                                                    })
                                                  },
                                                },
                                                model: {
                                                  value: _vm.staffForm.rateType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staffForm,
                                                      "rateType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staffForm.rateType",
                                                },
                                              },
                                              "v-select",
                                              _vm.tableInputProps,
                                              false
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "th",
                                        [
                                          _c(
                                            "v-text-field",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.decimal,
                                                  ],
                                                  label: "Hourly Rate",
                                                },
                                                model: {
                                                  value:
                                                    _vm.staffForm.hourlyRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staffForm,
                                                      "hourlyRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staffForm.hourlyRate",
                                                },
                                              },
                                              "v-text-field",
                                              _vm.tableInputProps,
                                              false
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "th",
                                        [
                                          _c(
                                            "v-text-field",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  rules: [_vm.rules.required],
                                                  label: "Break (minutes)",
                                                  type: "number",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "append-outer",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "InvoiceDetailsBreakCalculator",
                                                            {
                                                              model: {
                                                                value:
                                                                  _vm.staffForm
                                                                    .breaks,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.staffForm,
                                                                      "breaks",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "staffForm.breaks",
                                                              },
                                                            }
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.staffForm.breaks,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staffForm,
                                                      "breaks",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staffForm.breaks",
                                                },
                                              },
                                              "v-text-field",
                                              _vm.tableInputProps,
                                              false
                                            )
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("th", { staticClass: "text-right" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.computeCost(_vm.staffForm) ?? ""
                                          )
                                        ),
                                      ]),
                                      _c("th"),
                                    ])
                                  : _vm._e(),
                                _vm.showAddNewRow === staffType
                                  ? _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: {
                                            colspan:
                                              _vm.updatedHeaders.length + 1,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                disabled:
                                                  _vm.invoiceIsFetchingStaffCost,
                                                small: "",
                                                tile: "",
                                                depressed: "",
                                                color: "success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveStaffCost({
                                                    form: _vm.staffForm,
                                                  })
                                                },
                                              },
                                            },
                                            [_vm._v("Save")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.invoiceIsFetchingStaffCost,
                                                small: "",
                                                tile: "",
                                                depressed: "",
                                                color: "error",
                                              },
                                              on: {
                                                click: _vm.hideStaffCostForm,
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("tr", [
                                  _c(
                                    "th",
                                    [
                                      !_vm.invoiceReadOnly
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                disabled: !!_vm.showAddNewRow,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showStaffCostForm(
                                                    staffType
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-plus")]
                                              ),
                                              _vm._v(" New Line "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("th", {
                                    attrs: {
                                      colspan:
                                        _vm.updatedHeaders.length -
                                        (_vm.showStartEndTime ? 2 : 3),
                                    },
                                  }),
                                  _vm.invoiceConfig
                                    .calculateBenefitsWithDriverRate
                                    ? _c("th", { staticClass: "text-right" }, [
                                        _vm._v(
                                          " Benefits: $ " +
                                            _vm._s(
                                              _vm
                                                .getTotalBenefit(staffType)
                                                .toFixed(2)
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v(
                                      "Total: $" +
                                        _vm._s(_vm.totalCost[staffType])
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      }),
      _vm.editDialog.visible
        ? _c("InvoiceDetailsEditStaffCost", {
            attrs: {
              details: _vm.editDialog.details,
              visible: _vm.editDialog.visible,
              updateHours: _vm.updateHours,
              computeCost: _vm.computeCost,
              save: _vm.saveStaffCost,
              invoiceRate: _vm.invoiceConfig.invoiceRate,
              districtRateTypes: _vm.districtRateTypes,
              setHourlyRate: _vm.setHourlyRate,
              currentStaff: _vm.currentStaff,
              "show-start-end-time": _vm.showStartEndTime,
              "date-validation-details": {
                minimumDate: _vm.minimumDate,
                maximumDate: _vm.maximumDate,
                dateStringMessage: _vm.rules.dateStringMessage,
              },
              "driver-assistant-label": _vm.driverAssistantLabel,
            },
            on: {
              "update:visible": [
                function ($event) {
                  return _vm.$set(_vm.editDialog, "visible", $event)
                },
                _vm.hideStaffCostForm,
              ],
            },
          })
        : _vm._e(),
      _c("CustomFormFieldsDisplay", {
        attrs: {
          "is-readonly": _vm.invoiceReadOnly,
          section: "Staff Costs",
          parentId: _vm.invoiceId,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }