var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { align: "stretch" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "9" } },
        [
          _c("CardBordered", {
            attrs: { color: _vm.content.isPaymentApproved ? "green" : "blue" },
            scopedSlots: _vm._u([
              {
                key: "left",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "large-link d-flex align-center",
                        on: {
                          click: function ($event) {
                            return _vm.showTrip()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column justify-center" },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.content.tripRequestId)),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      { staticClass: "pa-2" },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "6", lg: "6" } },
                          _vm._l(_vm.firstHalf, function (item, index) {
                            return _c(
                              "v-row",
                              { key: index, attrs: { dense: "" } },
                              [
                                _c("v-col", { attrs: { cols: "12" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-right font-weight-bold",
                                    },
                                    [_vm._v(_vm._s(item.label) + ":")]
                                  ),
                                  _vm._v(" " + _vm._s(item.value) + " "),
                                ]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _vm.rows.length > 5
                          ? _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: "12",
                                  sm: "6",
                                  md: "6",
                                  lg: "6",
                                },
                              },
                              _vm._l(_vm.secondHalf, function (item, index) {
                                return _c(
                                  "v-row",
                                  { key: index, attrs: { dense: "" } },
                                  [
                                    _c("v-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-right font-weight-bold",
                                        },
                                        [_vm._v(_vm._s(item.label) + ":")]
                                      ),
                                      _vm._v(" " + _vm._s(item.value) + " "),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "3" } },
        [
          _c("CardBordered", {
            attrs: { color: "green" },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-simple-table", {
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v("Staff Costs:")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.toDollarAmount(
                                                    _vm.invoiceTotalCosts.staff
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v("Travel Costs:")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.toDollarAmount(
                                                    _vm.invoiceTotalCosts.travel
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v("Additional Costs:")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.toDollarAmount(
                                                    _vm.invoiceTotalCosts
                                                      .additional
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v("Invoice Total:")]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.toDollarAmount(
                                                    _vm.invoiceTotalCosts.total
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }