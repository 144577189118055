var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading
        ? _c(
            "v-row",
            { staticClass: "pb-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "primary",
                      absolute: "",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "section",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-select",
                    _vm._b(
                      {
                        attrs: {
                          items: _vm.enumToArray(_vm.travelTypeMap),
                          label: "Travel Input",
                          disabled: _vm.invoiceReadOnly || _vm.isLoading,
                        },
                        model: {
                          value: _vm.travelType,
                          callback: function ($$v) {
                            _vm.travelType = $$v
                          },
                          expression: "travelType",
                        },
                      },
                      "v-select",
                      _vm.inputProps,
                      false
                    )
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                _vm._l(_vm.rateTypes, function (item) {
                  return _c(
                    "v-checkbox",
                    _vm._b(
                      {
                        key: item.type,
                        attrs: {
                          disabled: _vm.invoiceReadOnly || _vm.isLoading,
                          readonly:
                            _vm.selectedRateTypes?.length === 1 &&
                            _vm.selectedRateTypes[0] === item.type,
                          label: item.text,
                          value: item.type,
                        },
                        on: { change: _vm.recalculateEntries },
                        model: {
                          value: _vm.selectedRateTypes,
                          callback: function ($$v) {
                            _vm.selectedRateTypes = $$v
                          },
                          expression: "selectedRateTypes",
                        },
                      },
                      "v-checkbox",
                      { dense: true, "hide-details": true },
                      false
                    )
                  )
                }),
                1
              ),
              _c("v-col"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "py-3" },
        [
          _c(
            "v-form",
            {
              ref: "formTravelCost",
              model: {
                value: _vm.travelCostForm.valid,
                callback: function ($$v) {
                  _vm.$set(_vm.travelCostForm, "valid", $$v)
                },
                expression: "travelCostForm.valid",
              },
            },
            [
              _c("Table", {
                attrs: {
                  menuOptions: _vm.menuOptions,
                  itemsPerPage: -1,
                  color: _vm.colors.lightBlue,
                  sortable: "",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  "show-disabled": false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("tfoot", [
                          _vm.travelCostForm.show
                            ? _c("tr", [
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.date !== null
                                      ? _c(
                                          "DatePicker",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.date,
                                                  _vm.rules.minDate(
                                                    _vm.minimumDate,
                                                    _vm.rules.dateStringMessage
                                                  ),
                                                  _vm.rules.maxDate(
                                                    _vm.maximumDate,
                                                    _vm.rules.dateStringMessage
                                                  ),
                                                  _vm.rules.allowedDates(
                                                    _vm.travelCostDates
                                                  ),
                                                ],
                                                label: "Date",
                                              },
                                              model: {
                                                value: _vm.travelCostForm.date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "date",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.date",
                                              },
                                            },
                                            "DatePicker",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.pickUp !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.decimal,
                                                ],
                                                label: "Pick Up (mi)",
                                                type: "number",
                                                hint: _vm.pickUpMilesHint,
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm.pickUp,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "pickUp",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.pickUp",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.startOdometer !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.numeric,
                                                  _vm.rules.min(0),
                                                  _vm.rules.max(
                                                    _vm.travelCostForm
                                                      .endOdometer
                                                  ),
                                                  _vm.rules.decimal,
                                                ],
                                                label: "Start Odometer",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm
                                                    .startOdometer,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "startOdometer",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.startOdometer",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.endOdometer !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.numeric,
                                                  _vm.rules.min(
                                                    _vm.travelCostForm
                                                      .startOdometer
                                                  ),
                                                  _vm.rules.decimal,
                                                ],
                                                label: "End Odometer",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm
                                                    .endOdometer,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "endOdometer",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.endOdometer",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.milesPerGallon !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.numeric,
                                                  _vm.rules.min(1),
                                                  _vm.rules.decimal,
                                                ],
                                                label: "Miles Per Gallon",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm
                                                    .milesPerGallon,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "milesPerGallon",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.milesPerGallon",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.fuelUsed !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.numeric,
                                                  _vm.rules.min(0.01),
                                                  _vm.rules.decimal,
                                                ],
                                                label: "Fuel (gal)",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm.fuelUsed,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "fuelUsed",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.fuelUsed",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.fuelRate !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.numeric,
                                                  _vm.rules.min(0),
                                                  _vm.rules.decimal,
                                                ],
                                                label: "Fuel Rate",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm.fuelRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "fuelRate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.fuelRate",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.mileage !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.decimal,
                                                ],
                                                label: "Mileage (mi)",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm.mileage,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "mileage",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.mileage",
                                              },
                                            },
                                            "v-text-field",
                                            {
                                              ..._vm.inputProps,
                                              readonly: _vm.hasOdometerReading(
                                                _vm.travelCostForm
                                              ),
                                              disabled: _vm.hasOdometerReading(
                                                _vm.travelCostForm
                                              ),
                                            },
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.mileageRate !== null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.decimal,
                                                ],
                                                label: "Mileage Rate",
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm
                                                    .mileageRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "mileageRate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.mileageRate",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "th",
                                  [
                                    _vm.travelCostForm.additionalMileageCost !==
                                    null
                                      ? _c(
                                          "v-text-field",
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.required,
                                                  _vm.rules.decimal,
                                                ],
                                                label:
                                                  "Additional Cost Per Mile",
                                                readonly:
                                                  !_vm.canEditAdditionalCostPerMile,
                                                type: "number",
                                              },
                                              model: {
                                                value:
                                                  _vm.travelCostForm
                                                    .additionalMileageCost,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.travelCostForm,
                                                    "additionalMileageCost",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "travelCostForm.additionalMileageCost",
                                              },
                                            },
                                            "v-text-field",
                                            _vm.inputProps,
                                            false
                                          )
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.computedTravelCost())),
                                ]),
                                _c("th"),
                              ])
                            : _vm._e(),
                          _vm.travelCostForm.show
                            ? _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "text-center",
                                    attrs: { colspan: _vm.headers.length + 1 },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          loading: _vm.isLoading,
                                          small: "",
                                          tile: "",
                                          depressed: "",
                                          color: "success",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.saveTravelCost({
                                              isFormValid:
                                                _vm.travelCostForm.valid,
                                              validate: () =>
                                                _vm.validateForm(
                                                  _vm.$refs.formTravelCost
                                                ),
                                              success: _vm.initTravelCostForm,
                                              form: _vm.travelCostForm,
                                            })
                                          },
                                        },
                                      },
                                      [_vm._v("Save")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          loading: _vm.isLoading,
                                          small: "",
                                          tile: "",
                                          depressed: "",
                                          color: "error",
                                        },
                                        on: { click: _vm.initTravelCostForm },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c(
                              "th",
                              [
                                !_vm.invoiceReadOnly
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          text: "",
                                          disabled:
                                            _vm.isFetchingPickupMiles ||
                                            _vm.travelCostForm.show,
                                        },
                                        on: { click: _vm.showTravelCostForm },
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("mdi-plus"),
                                        ]),
                                        _c("span", [_vm._v("New Line")]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("th", {
                              attrs: { colspan: _vm.headers.length - 2 },
                            }),
                            _c("th", [
                              _vm._v(
                                "Total: " + _vm._s(_vm.grandTotalTravelCost)
                              ),
                            ]),
                            _c("th"),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.editDialog.visible
        ? _c("InvoiceDetailsEditTravelCost", {
            attrs: {
              details: _vm.editDialog.details,
              visible: _vm.editDialog.visible,
              fields: _vm.headers,
              rates: _vm.selectedRateTypes,
              setFormMileage: _vm.setFormMileage,
              validateForm: _vm.validateForm,
              save: _vm.saveTravelCost,
              hasOdometerReading: _vm.hasOdometerReading,
              pickUpMilesHint: _vm.pickUpMilesHint,
              "date-validation-details": {
                minimumDate: _vm.minimumDate,
                maximumDate: _vm.maximumDate,
                dateStringMessage: _vm.rules.dateStringMessage,
              },
              canEditAdditionalCostPerMile: _vm.canEditAdditionalCostPerMile,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.editDialog, "visible", $event)
              },
            },
          })
        : _vm._e(),
      _c("CustomFormFieldsDisplay", {
        attrs: {
          "is-readonly": _vm.invoiceReadOnly,
          section: "Travel Costs",
          parentId: _vm.invoiceId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }