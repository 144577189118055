<template>
  <v-row align="stretch">
    <v-col cols="9">
      <!-- INVOICE -->
      <CardBordered :color="content.isPaymentApproved ? 'green' : 'blue'">
        <template #left>
          <div @click="showTrip()" class="large-link d-flex align-center">
            <div class="d-flex flex-column justify-center">
              <div>{{ content.tripRequestId }}</div>
            </div>
          </div>
        </template>
        <template #body>
          <v-row class="pa-2">
            <!-- First Column -->
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-row dense v-for="(item, index) in firstHalf" :key="index">
                <v-col cols="12">
                  <span class="text-right font-weight-bold">{{ item.label }}:</span> {{ item.value }}
                </v-col>
              </v-row>
            </v-col>

            <!-- Second Column -->
            <v-col v-if="rows.length > 5" cols="12" sm="6" md="6" lg="6">
              <v-row dense v-for="(item, index) in secondHalf" :key="index">
                <v-col cols="12">
                  <span class="text-right font-weight-bold">{{ item.label }}:</span> {{ item.value }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </CardBordered>
    </v-col>
    <v-col cols="3">
      <!-- COST -->
      <CardBordered color="green">
        <template #body>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Staff Costs:</td>
                      <td class="text-right">{{ toDollarAmount(invoiceTotalCosts.staff) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Travel Costs:</td>
                      <td class="text-right">{{ toDollarAmount(invoiceTotalCosts.travel) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Additional Costs:</td>
                      <td class="text-right">{{ toDollarAmount(invoiceTotalCosts.additional) }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Invoice Total:</td>
                      <td class="text-right">{{ toDollarAmount(invoiceTotalCosts.total) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </template>
      </CardBordered>
    </v-col>
  </v-row>
</template>
<script>
import { CardBordered } from '@/components/shared';
import { format } from 'date-fns';
import { mapGetters, mapActions } from 'vuex';
import { toDollarAmount } from '@/util';
export default {
  props: { content: { type: Object, default: () => ({}) } },
  components: { CardBordered },
  data() {
    return {
      toDollarAmount,
    };
  },
  computed: {
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('vehicle', ['vehicles']),
    ...mapGetters('location', ['locationsById', 'locations']),
    ...mapGetters('config', [
      'tripRequestConfig',
      'specialIndicators',
      'driverConfig',
      'permissions',
      'notifyConfig',
      'invoiceConfig',
    ]),
    ...mapGetters('app', ['clientConfig', 'clientAddress']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('invoice', ['invoiceTotalCosts']),
    tripType() {
      if (this.content.tripType) return this.content.tripType;

      const tt = this.tripTypesById[this.content.tripTypeId];
      return tt ? tt.name : '';
    },
    tripEvents() {
      return this.content.tripEvents ?? '';
    },
    vehicle() {
      const vehicle = this.vehicles.find((vehicle) => vehicle.id === this.content.vehicleId);
      if (!vehicle) return '';
      return vehicle.plateNum ? `${vehicle.name} - ${vehicle.plateNum}` : vehicle.name;
    },
    vehicleLocation() {
      const vehicle = this.vehicles.find((vehicle) => vehicle.id === this.content.vehicleId);
      if (!vehicle) return '';
      const location = this.locations.find((location) => location.depotId === vehicle.depotId);
      return location ? location.name : '';
    },
    vehicleType() {
      const vt = this.vehicleTypesById[this.content.vehicleType];
      return vt ? vt.name : '';
    },
    vehicleOwner() {
      return this.content.vehicleOwner ?? '';
    },
    indicators() {
      const indicators = [];
      if (this.content.haveHealthConcerns) indicators.push('Health Concerns');
      if (this.content.needLunch > 0) indicators.push('Lunch Required');
      if (this.content.awayForLunch && !this.content.needLunch) indicators.push('Lunch Provided');

      return indicators.length > 0 ? indicators.join(', ') : 'None';
    },
    zone() {
      if (this.tripRequestConfig && this.tripRequestConfig.other) {
        if (this.tripRequestConfig.other.determineZoneBy == 'request')
          return this.content.locationId ? this.locationsById[this.content.locationId]?.zone : 'N/A';
        if (this.tripRequestConfig.other.determineZoneBy == 'vehicle') {
          const reserveFromLocation = this.locationsById[this.content.locationId].vehicleOrder[0] || 0;
          return this.locationsById[reserveFromLocation]?.zone || '444';
        }
      }
      return '-';
    },
    leaveDate() {
      const d = new Date(this.content.leaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd yy');
    },
    leaveTime() {
      const s = this.content.leaveTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    returnDate() {
      const d = new Date(this.content.returnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      return format(dt, 'LLL dd yyyy');
    },
    returnTime() {
      const s = this.content.returnTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${hour}:${minute} ${ampm}`;
    },
    destinationName() {
      return this.destinationsById[this.content.destinationId]
        ? this.destinationsById[this.content.destinationId].name
        : '-';
    },
    driverName() {
      return this.content.driver || '';
    },
    locationName() {
      if (!this.content.locationId) {
        return '';
      }
      return this.locationsById[this.content.locationId]?.name || '';
    },
    rows() {
      const data = [];

      data.push(
        ...[
          { label: 'Vehicle Location', value: this.vehicleLocation },
          { label: 'Vehicle Type', value: this.vehicleType },
          { label: 'Vehicle Owner', value: this.vehicleOwner },
          { label: 'Start', value: this.leaveDate + ' ' + this.leaveTime + ' - ' + this.locationName },
          { label: 'End', value: this.returnDate + ' ' + this.returnTime + ' - ' + this.destinationName },
          { label: 'Trip Type', value: this.tripType },
          { label: 'Trip Event', value: this.tripEvents },
          { label: 'Zone', value: this.zone },
          { label: 'Driver', value: this.driverName },
          { label: 'Vehicle #', value: this.vehicle },
          // { label: 'Bid ID', value: '' },
          { label: 'Indicator', value: this.indicators },
        ]
      );
      return data;
    },
    firstHalf() {
      const endLocation = this.rows.findIndex((r) => r.label === 'End');
      return this.rows.slice(0, endLocation + 1);
    },
    secondHalf() {
      const startLocation = this.rows.findIndex((r) => r.label === 'Trip Type');
      return this.rows.slice(startLocation);
    },
  },
  methods: {
    ...mapActions('invoice', ['getInvoiceDetails']),
    async getAllData() {
      if (!this.content.id) return;
      await this.getInvoiceDetails({ tab: 'staff_cost', invoiceId: this.content.id });
      await this.getInvoiceDetails({ tab: 'travel_cost', invoiceId: this.content.id });
      await this.getInvoiceDetails({ tab: 'additional_charge', invoiceId: this.content.id });
    },
    showTrip() {
      const route = this.$router.resolve({ path: `/trip-request/${this.content.tripRequestId}` });
      window.open(route.href, '_blank');
    },
  },
  async mounted() {
    await this.getAllData();
  },
};
</script>

<style scoped>
.large-link {
  cursor: pointer;
  overflow-wrap: anywhere;
  font-size: 2rem;
}
</style>
