var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8 pt-0 relative", attrs: { fluid: "" } },
    [
      _c(
        "section",
        { staticClass: "context-menu py-3" },
        [
          _c(
            "v-row",
            { staticClass: "mb-4 mt-2" },
            [
              _c(
                "v-col",
                { staticClass: "d-flex py-0", attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-chip",
                    { staticClass: "button-chip", attrs: { label: "" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.invoicePaginationDetails.total) +
                          " " +
                          _vm._s(
                            _vm.pluralize(
                              "Invoice",
                              _vm.invoicePaginationDetails.total
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "my-0 mx-2",
                  attrs: {
                    dark: "",
                    color: "primary",
                    loading: _vm.isPrinting,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.print()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-printer")])],
                1
              ),
              _c("v-switch", {
                staticClass: "ma-0 pa-0 mx-4 mt-2 d-inline-block",
                attrs: { label: "Expand All", "hide-details": "" },
                model: {
                  value: _vm.allExpanded,
                  callback: function ($$v) {
                    _vm.allExpanded = $$v
                  },
                  expression: "allExpanded",
                },
              }),
            ],
            1
          ),
          !_vm.hideSubMenu
            ? _c(
                "v-toolbar",
                { attrs: { height: "50", elevation: "4", rounded: "" } },
                [
                  _c("InvoiceListMenu", {
                    attrs: { filterText: _vm.filterText },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("Table", {
        staticClass: "mt-2 px-1",
        attrs: {
          "current-page": _vm.invoicePaginationDetails.currentPage,
          "server-side": true,
          headers: _vm.headers,
          "items-per-page": 20,
          loading: _vm.invoiceIsFetching,
          "menu-options": _vm.menuOptions,
          rows: _vm.invoices,
          "total-items": _vm.invoicePaginationDetails.total,
          fixedHeader: "",
          height: _vm.tableHeight,
          "expand-row": _vm.allExpanded,
        },
        on: { pagination: _vm.setPagination },
        scopedSlots: _vm._u([
          {
            key: "column_staffCost",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.toDollarAmount(item.staffCost))),
                ]),
              ]
            },
          },
          {
            key: "column_travelCost",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.toDollarAmount(item.travelCost))),
                ]),
              ]
            },
          },
          {
            key: "column_additionalCost",
            fn: function ({ item }) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.toDollarAmount(item.additionalCost))),
                ]),
              ]
            },
          },
          {
            key: "column_total",
            fn: function ({ item }) {
              return [
                _c("span", [_vm._v(_vm._s(_vm.toDollarAmount(item.total)))]),
              ]
            },
          },
          {
            key: "column_tripDate",
            fn: function ({ item }) {
              return [
                _c("div", [
                  _vm._v(" " + _vm._s(_vm.toDateString(item.leaveDate)) + " "),
                  !_vm.isSameDate(item.leaveDate, item.returnDate)
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "darken-2" } },
                            [_vm._v(" mdi-arrow-right ")]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.toDateString(item.returnDate))
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "column_status",
            fn: function ({ header, item }) {
              return [
                _vm.fundingSourceLimit > 1
                  ? _c(
                      "v-chip",
                      {
                        staticClass: "font-weight-bold",
                        attrs: {
                          color: _vm.chipStatus({
                            header,
                            item,
                            type: "color",
                          }),
                          small: "",
                          label: "",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.chipStatus({ header, item, type: "text" })
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.fundingSourceLimit === 1
                  ? _c("v-select", {
                      staticClass: "invoice-status",
                      attrs: {
                        chips: "",
                        disabled: !_vm.canUpdateInvoiceStatus(item.status),
                        "hide-details": true,
                        "hide-selected": true,
                        items: _vm.getFilteredInvoiceStatusOptions(item.status),
                        value: item.status,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onChipStatusSelect($event, item)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function () {
                              return [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: {
                                      color: _vm.chipStatus({
                                        header,
                                        item,
                                        type: "color",
                                      }),
                                      small: "",
                                      label: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.chipStatus({
                                            header,
                                            item,
                                            type: "text",
                                          })
                                        ) +
                                        " "
                                    ),
                                    _vm.canUpdateInvoiceStatus(item.status)
                                      ? _c(
                                          "v-avatar",
                                          { attrs: { right: "" } },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-chevron-down"),
                                            ]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "expansion",
            fn: function ({ header, item }) {
              return [
                _c("v-simple-table", { staticClass: "table-expanded-row" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Funding Source")]),
                      _c("th", [_vm._v("Budget Code")]),
                      _c("th", [_vm._v("Split Type")]),
                      _c("th", [_vm._v("Batch/Journal Number")]),
                      _c("th", [_vm._v("Check Number")]),
                      _c("th", [_vm._v("Amount")]),
                      _c("th"),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(item.invoicePayments, function (row) {
                      return _c(
                        "tr",
                        { key: row.id, staticClass: "expanded-row-fit" },
                        [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.fundingSource?.name
                                    ? row.fundingSource.name
                                    : "N/A"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(row.budgetCode ? row.budgetCode : "N/A")
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.fundingSourceType
                                    ? row.fundingSourceType
                                    : "N/A"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                row.batchJournalNumber
                                  ? row.batchJournalNumber
                                  : "N/A"
                              )
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(row.checkNumber ? row.checkNumber : "N/A")
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              row.fundingSourceType === "percentage"
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.toDollarAmount(row.amount)) +
                                        " " +
                                        _vm._s(`(${row.percentage}%)`) +
                                        " "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      _vm._s(_vm.toDollarAmount(row.amount))
                                    ),
                                  ],
                            ],
                            2
                          ),
                          _c(
                            "td",
                            [
                              _vm.canUpdateInvoiceFundingStatus(item)
                                ? _c("v-select", {
                                    staticClass: "invoice-status",
                                    attrs: {
                                      chips: "",
                                      disabled:
                                        !_vm.canUpdateInvoicePaymentStatus(row),
                                      "hide-details": true,
                                      "hide-selected": true,
                                      items:
                                        _vm.getFilteredInvoiceStatusOptions(
                                          row.status
                                        ),
                                      value: row.status,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onFundingSourceChipStatusSelect(
                                          $event,
                                          item,
                                          row
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "font-weight-bold",
                                                  attrs: {
                                                    color: _vm.chipStatus({
                                                      header,
                                                      item: row,
                                                      type: "color",
                                                    }),
                                                    small: "",
                                                    label: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.chipStatus({
                                                          header,
                                                          item: row,
                                                          type: "text",
                                                        })
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.canUpdateInvoicePaymentStatus(
                                                    row
                                                  ) && row.status !== "paid"
                                                    ? _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: { right: "" },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-chevron-down"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("InvoiceDrawer", { on: { close: _vm.hideInvoice } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }