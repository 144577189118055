var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Dialog", {
    attrs: { value: _vm.visible },
    on: { input: _vm.handleClose },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _vm._v(" Edit " + _vm._s(_vm.staffType) + " Staff Cost Details "),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-form",
              { ref: "form" },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "DatePicker",
                          _vm._b(
                            {
                              attrs: {
                                label: "Date",
                                rules: [
                                  _vm.required,
                                  _vm.minDate(
                                    _vm.dateValidationDetails.minimumDate,
                                    _vm.dateValidationDetails.dateStringMessage
                                  ),
                                  _vm.maxDate(
                                    _vm.dateValidationDetails.maximumDate,
                                    _vm.dateValidationDetails.dateStringMessage
                                  ),
                                ],
                              },
                              model: {
                                value: _vm.form.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "date", $$v)
                                },
                                expression: "form.date",
                              },
                            },
                            "DatePicker",
                            _vm.inputProps,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                    _vm.showStartEndTime
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c(
                              "TimePicker",
                              _vm._b(
                                {
                                  attrs: { label: "Start Time" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateHours(_vm.form)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.startTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "startTime", $$v)
                                    },
                                    expression: "form.startTime",
                                  },
                                },
                                "TimePicker",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showStartEndTime
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6" } },
                          [
                            _c(
                              "TimePicker",
                              _vm._b(
                                {
                                  attrs: { label: "End Time" },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateHours(_vm.form)
                                    },
                                  },
                                  model: {
                                    value: _vm.form.endTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "endTime", $$v)
                                    },
                                    expression: "form.endTime",
                                  },
                                },
                                "TimePicker",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c(
                          "v-text-field",
                          _vm._b(
                            {
                              attrs: {
                                rules: [_vm.required, _vm.decimal],
                                label: "Hours",
                              },
                              model: {
                                value: _vm.form.hours,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hours", $$v)
                                },
                                expression: "form.hours",
                              },
                            },
                            "v-text-field",
                            _vm.inputProps,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                    _vm.invoiceRate
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "4" } },
                          [
                            _c(
                              "v-select",
                              _vm._b(
                                {
                                  attrs: {
                                    label: "District Wide Rate Type",
                                    rules: [_vm.required],
                                    items: _vm.enumToArray(
                                      _vm.districtRateTypes
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.setHourlyRate({
                                        form: _vm.form,
                                        staff:
                                          _vm.currentStaff[_vm.form.staffType],
                                        staffType: _vm.form.staffType,
                                      })
                                    },
                                  },
                                  model: {
                                    value: _vm.form.districtRateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "districtRateType",
                                        $$v
                                      )
                                    },
                                    expression: "form.districtRateType",
                                  },
                                },
                                "v-select",
                                _vm.inputProps,
                                false
                              )
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c(
                          "v-select",
                          _vm._b(
                            {
                              attrs: {
                                label: "Rate Type",
                                rules: [_vm.required],
                                items: _vm.enumToArray(
                                  _vm.staffCostMap.rateType
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.setHourlyRate({
                                    form: _vm.form,
                                    staff: _vm.currentStaff[_vm.form.staffType],
                                    staffType: _vm.form.staffType,
                                  })
                                },
                              },
                              model: {
                                value: _vm.form.rateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "rateType", $$v)
                                },
                                expression: "form.rateType",
                              },
                            },
                            "v-select",
                            _vm.inputProps,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c(
                          "v-text-field",
                          _vm._b(
                            {
                              attrs: {
                                rules: [_vm.required, _vm.decimal],
                                label: "Hourly Rate",
                              },
                              model: {
                                value: _vm.form.hourlyRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "hourlyRate", $$v)
                                },
                                expression: "form.hourlyRate",
                              },
                            },
                            "v-text-field",
                            _vm.inputProps,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "4" } },
                      [
                        _c(
                          "v-text-field",
                          _vm._b(
                            {
                              attrs: {
                                type: "number",
                                rules: [_vm.required],
                                label: "Break (minutes)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      _c("InvoiceDetailsBreakCalculator", {
                                        model: {
                                          value: _vm.form.breaks,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "breaks", $$v)
                                          },
                                          expression: "form.breaks",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                              model: {
                                value: _vm.form.breaks,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "breaks", $$v)
                                },
                                expression: "form.breaks",
                              },
                            },
                            "v-text-field",
                            _vm.inputProps,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-right text-h5 font-weight-bold mt-4" },
                  [_vm._v("Total Cost: $" + _vm._s(_vm.computeCost(_vm.form)))]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "v-btn",
              {
                attrs: {
                  loading: _vm.invoiceIsFetchingStaffCost,
                  color: "success",
                },
                on: { click: _vm.saveForm },
              },
              [
                _c("v-icon", { attrs: { left: "" } }, [
                  _vm._v("mdi-content-save"),
                ]),
                _vm._v(" Save "),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }