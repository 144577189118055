var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "pa-0", attrs: { fluid: "" } }, [
    _c("div", { staticClass: "d-flex align-center" }, [
      _c(
        "div",
        { staticClass: "mr-auto" },
        [
          _c(
            "v-chip",
            {
              staticClass: "font-weight-bold",
              attrs: { color: _vm.status.color, label: "" },
            },
            [_vm._v(" " + _vm._s(_vm.status.text) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("upload-file", {
            ref: "uploadFile",
            attrs: {
              recordType: "invoice",
              recordId: _vm.content.id,
              showSize: "",
              persistentHint: "",
              hideInput: true,
              readonly: false,
            },
            on: { onUpload: _vm.onUpload },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { icon: "", loading: _vm.isPrinting },
              on: { click: _vm.handlePrint },
            },
            [_c("v-icon", [_vm._v("mdi-printer")])],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { icon: "" },
              on: { click: _vm.handleDownload },
            },
            [_c("v-icon", [_vm._v("mdi-download")])],
            1
          ),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.handleClose } },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }