var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoiceArrayFilters.length
    ? _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 200,
            "offset-y": "",
            "min-width": "350px",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "text-capitalize",
                            class: { flash: _vm.filtersChanged },
                            attrs: { text: "" },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("mdi-filter"),
                        ]),
                        _vm._v(
                          " Active Filters (" +
                            _vm._s(_vm.invoiceArrayFilters.length) +
                            ") "
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            1165888539
          ),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "card-content" },
            [
              _c(
                "v-list",
                { staticClass: "active-filter-field" },
                _vm._l(_vm.invoiceArrayFilters, function (filter, index) {
                  return _c(
                    "Chip",
                    {
                      key: index,
                      staticClass: "chips",
                      attrs: {
                        close: "",
                        disabled: _vm.isInvoiceFilterDisabled,
                        wrap: "",
                      },
                      on: {
                        "click:close": function ($event) {
                          return _vm.removeFilter(filter)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(filter.text) + " ")]
                  )
                }),
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "card-actions" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.isInvoiceFilterDisabled,
                        color: "#ff4242",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeFilter("all")
                        },
                      },
                    },
                    [_vm._v(" Clear All ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }