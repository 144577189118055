var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.invoiceIsFetchingAdditionalCharge
        ? _c(
            "v-row",
            { staticClass: "pb-2" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "primary",
                      absolute: "",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._l(
        _vm.invoiceAdditionalChargeList?.invoiceAdditionalCharge,
        function (additionalCharge, index) {
          return _c(
            "div",
            { key: index },
            [
              _c(
                "div",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "10" } },
                        [
                          _c("v-combobox", {
                            attrs: {
                              disabled: _vm.invoiceReadOnly,
                              items: _vm.additionalChargeList,
                              "item-text": "chargeType",
                              "return-object": "",
                              label: "+ Enter New Cost Type",
                              outlined: "",
                              rules: [_vm.required],
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChangeChargeType(
                                  $event,
                                  additionalCharge
                                )
                              },
                              "update:search-input": function ($event) {
                                return _vm.handleSearchInput(
                                  $event,
                                  additionalCharge
                                )
                              },
                            },
                            model: {
                              value: additionalCharge.chargeType,
                              callback: function ($$v) {
                                _vm.$set(additionalCharge, "chargeType", $$v)
                              },
                              expression: "additionalCharge.chargeType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pb-0 pl-4", attrs: { cols: "2" } },
                        [
                          _c("v-text-field", {
                            ref: "amountField_" + index,
                            refInFor: true,
                            attrs: {
                              disabled: _vm.invoiceReadOnly,
                              placeholder: "0.00",
                              prefix: "$",
                              type: "number",
                              outlined: "",
                              rules: [_vm.required, _vm.numeric, _vm.decimal],
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChange(additionalCharge)
                              },
                            },
                            model: {
                              value: additionalCharge.amount,
                              callback: function ($$v) {
                                _vm.$set(additionalCharge, "amount", $$v)
                              },
                              expression: "additionalCharge.amount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "11" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.invoiceReadOnly,
                              "hide-details": "",
                              flat: "",
                              solo: "",
                              placeholder: "Enter description",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleChange(additionalCharge)
                              },
                            },
                            model: {
                              value: additionalCharge.description,
                              callback: function ($$v) {
                                _vm.$set(additionalCharge, "description", $$v)
                              },
                              expression: "additionalCharge.description",
                            },
                          }),
                        ],
                        1
                      ),
                      !_vm.invoiceReadOnly
                        ? _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    fab: "",
                                    text: "",
                                    small: "",
                                    color: "red",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDeleteExistingAdditionalCharge(
                                        additionalCharge.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-delete"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider", { staticClass: "divider mb-3" }),
            ],
            1
          )
        }
      ),
      _c(
        "v-row",
        { staticClass: "py-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              !_vm.invoiceReadOnly
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        plain: "",
                        loading: _vm.invoiceIsFetchingAdditionalCharge,
                      },
                      on: { click: _vm.addNewForm },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _c("span", [_vm._v("Add")]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("CustomFormFieldsDisplay", {
        attrs: {
          "is-readonly": _vm.invoiceReadOnly,
          section: "Additional Costs",
          parentId: _vm.invoiceId,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }