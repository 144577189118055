var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-2",
              attrs: { loading: _vm.isDownloadOngoing },
              on: { click: _vm.processDownload },
            },
            [
              _c("v-icon", [_vm._v("mdi-download")]),
              _vm._v(" Download Selected "),
            ],
            1
          ),
          !_vm.invoiceReadOnly
            ? _c(
                "v-btn",
                {
                  staticClass: "ml-5 mb-2",
                  attrs: { loading: _vm.isUploading },
                  on: { click: _vm.clickUpload },
                },
                [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" ATTACHMENT ")],
                1
              )
            : _vm._e(),
          _c("upload-file", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
            ref: "uploadFile",
            attrs: {
              recordType: "invoice",
              recordId: _vm.invoiceId,
              showSize: "",
              persistentHint: "",
              hideInput: true,
              readonly: false,
            },
            on: { onUpload: _vm.onUpload },
          }),
        ],
        1
      ),
      _c("Table", {
        attrs: {
          rows: _vm.invoiceDetailsAttachments,
          headers: _vm.headers,
          color: _vm.colors.lightRed,
          menuOptions: _vm.menuOptions,
          "show-select": "",
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c("CustomFormFieldsDisplay", {
        staticClass: "pt-4",
        attrs: {
          "is-readonly": _vm.invoiceReadOnly,
          section: "Attachments",
          parentId: _vm.invoiceId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }