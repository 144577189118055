var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-tabs",
        {
          staticClass: "tt-tabs",
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.filteredTabs, function (tab) {
          return _c("v-tab", { key: tab.name, attrs: { dark: "" } }, [
            _vm._v(_vm._s(tab.name)),
          ])
        }),
        1
      ),
      _c("v-divider"),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.filteredTabs, function (tab, tabIndex) {
          return _c(
            "v-tab-item",
            { key: tab.name },
            [
              _vm.activeTab === tabIndex
                ? _c(tab.component, {
                    tag: "component",
                    attrs: {
                      assignmentId: _vm.content.assignmentId,
                      invoiceId: _vm.content.id,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }