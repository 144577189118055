var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        absolute: "",
        temporary: "",
        permanent: _vm.showDrawer,
        right: "",
        width: _vm.drawerWidth,
      },
      model: {
        value: _vm.showDrawer,
        callback: function ($$v) {
          _vm.showDrawer = $$v
        },
        expression: "showDrawer",
      },
    },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _vm.showDrawer
            ? _c("InvoiceHeaderButtons", {
                attrs: { content: _vm.selectedInvoice },
                on: {
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-list",
        { staticClass: "pa-0" },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-list-item-title",
                    [
                      _vm.showDrawer
                        ? _c("InvoiceDetails", {
                            attrs: { content: _vm.selectedInvoice },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }