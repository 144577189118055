var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Dialog", {
        attrs: { value: _vm.visible },
        on: { input: _vm.handleClose },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Add Comment")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-form",
                  {
                    model: {
                      value: _vm.form.valid,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "valid", $$v)
                      },
                      expression: "form.valid",
                    },
                  },
                  [
                    _c(
                      "v-textarea",
                      _vm._b(
                        {
                          attrs: {
                            maxlength: 300,
                            counter: "",
                            rules: [_vm.rules.required],
                            label: "Comment",
                          },
                          model: {
                            value: _vm.form.comment,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "comment", $$v)
                            },
                            expression: "form.comment",
                          },
                        },
                        "v-textarea",
                        _vm.inputProps,
                        false
                      )
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: !_vm.form.valid || _vm.loading,
                      loading: _vm.invoiceIsFetchingHistory,
                      color: "success",
                    },
                    on: { click: _vm.addComment },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-content-save"),
                    ]),
                    _vm._v(" Save "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }