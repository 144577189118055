var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    { staticClass: "w-full" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center w-full" },
        [
          _vm.onTextSearchDebounced
            ? _c("v-text-field", {
                staticClass: "search-field",
                attrs: {
                  "hide-details": "",
                  clearable: "",
                  "prepend-icon": "mdi-magnify",
                  "single-line": "",
                  disabled: _vm.isInvoiceFilterDisabled,
                },
                on: {
                  "click:clear": _vm.handleClearFilter,
                  input: _vm.onTextSearchDebounced,
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              })
            : _vm._e(),
          _vm.isOperationsEnabled
            ? _c(
                "div",
                [
                  _c("sub-menu", {
                    attrs: {
                      name: "Operations",
                      "menu-items": _vm.operationMenu,
                    },
                    on: { "sub-menu-click": _vm.operationSelected },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("sub-menu", {
                attrs: {
                  name: "Filters",
                  disabled: _vm.isInvoiceFilterDisabled,
                  "menu-items": _vm.filterMenuItems,
                },
                on: { "sub-menu-click": _vm.onMenuItemClick },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("sub-menu", {
                attrs: {
                  name: "Visibility",
                  disabled: _vm.isInvoiceFilterDisabled,
                  "menu-items": _vm.visibleColumnsSetup,
                },
                on: { "sub-menu-click": _vm.setColumnVisibility },
              }),
            ],
            1
          ),
          _c("InvoiceSort"),
          _c("v-spacer"),
          _c(
            "div",
            [
              _c("InvoiceActiveFilters"),
              _c("InvoiceColumnVisibility"),
              _c(
                "v-btn",
                {
                  staticClass: "text-capitalize",
                  attrs: {
                    disabled: _vm.isInvoiceFilterDisabled,
                    text: "",
                    color: "black",
                  },
                  on: { click: _vm.getInvoices },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-refresh"),
                  ]),
                  _vm._v(" Refresh "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("TTDialog", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Select a Date Range")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("DatePicker", {
                          attrs: { label: "Start Date" },
                          model: {
                            value: _vm.startDate,
                            callback: function ($$v) {
                              _vm.startDate = $$v
                            },
                            expression: "startDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "6" } },
                      [
                        _c("DatePicker", {
                          attrs: { label: "End Date" },
                          model: {
                            value: _vm.endDate,
                            callback: function ($$v) {
                              _vm.endDate = $$v
                            },
                            expression: "endDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.isOkButtonDisabled,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setDateFilter({
                          type: "range",
                          startDate: _vm.startDate,
                          endDate: _vm.endDate,
                        })
                      },
                    },
                  },
                  [_vm._v("OK")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showDateRangeFilter,
          callback: function ($$v) {
            _vm.showDateRangeFilter = $$v
          },
          expression: "showDateRangeFilter",
        },
      }),
      _c("TTDialog", {
        attrs: { width: "auto" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Select a Date Month")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-date-picker", {
                  attrs: { type: "month" },
                  model: {
                    value: _vm.startDate,
                    callback: function ($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.isOkButtonDisabled,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setDateFilter({
                          type: "year_month",
                          startDate: _vm.startDate,
                        })
                      },
                    },
                  },
                  [_vm._v("OK")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showDateYearMonthFilter,
          callback: function ($$v) {
            _vm.showDateYearMonthFilter = $$v
          },
          expression: "showDateYearMonthFilter",
        },
      }),
      _c("id-picker", {
        ref: "idPicker",
        attrs: {
          title: "Trip #",
          filterType: "invoice",
          filter: { name: "Trip #", field: "id" },
        },
      }),
      _c("InvoiceOperationModal", {
        attrs: { selectedOperation: _vm.selectedOperation },
        on: { updated: _vm.handleSuccessfulUpdate },
        model: {
          value: _vm.isShowOperationDialog,
          callback: function ($$v) {
            _vm.isShowOperationDialog = $$v
          },
          expression: "isShowOperationDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }