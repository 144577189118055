var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pt-0 px-1", attrs: { fluid: "" } },
    [
      _c("InvoiceDetailsHeader", {
        staticClass: "mb-4",
        attrs: { content: _vm.content },
      }),
      _c("InvoiceDetailsTabs", { attrs: { content: _vm.content } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }