var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        { attrs: { icon: "" }, on: { click: _vm.openCalc } },
        [_c("v-icon", [_vm._v("mdi-calculator")])],
        1
      ),
      _c("Dialog", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Enter Breaks")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._l(_vm.breaks, function (breakEntry, index) {
                  return _c(
                    "v-row",
                    { key: index },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "TimePicker",
                            _vm._b(
                              {
                                attrs: { label: "Start" },
                                model: {
                                  value: breakEntry.start,
                                  callback: function ($$v) {
                                    _vm.$set(breakEntry, "start", $$v)
                                  },
                                  expression: "breakEntry.start",
                                },
                              },
                              "TimePicker",
                              _vm.inputProps,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "TimePicker",
                            _vm._b(
                              {
                                attrs: { label: "End" },
                                model: {
                                  value: breakEntry.end,
                                  callback: function ($$v) {
                                    _vm.$set(breakEntry, "end", $$v)
                                  },
                                  expression: "breakEntry.end",
                                },
                              },
                              "TimePicker",
                              _vm.inputProps,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeBreak(index)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "v-row",
                  [
                    _c("v-col", [
                      _c(
                        "div",
                        { staticClass: "font-weight-bold text-h5 text-right" },
                        [
                          _vm._v(
                            "Total Breaks: " +
                              _vm._s(_vm.totalBreaks) +
                              " minutes"
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  { attrs: { color: "primary" }, on: { click: _vm.addBreak } },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                    _vm._v(" Add Break "),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { disabled: !_vm.breaks.length, color: "success" },
                    on: { click: _vm.saveBreaks },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-check"),
                    ]),
                    _vm._v(" OK "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.dialog,
          callback: function ($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }