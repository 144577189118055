<template>
  <v-menu
    v-if="invoiceHiddenColumns.length"
    v-model="showMenu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
    min-width="350px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="text-capitalize" v-bind="attrs" v-on="on" :class="{ flash: filtersChanged }">
        <v-icon left>mdi-eye-off</v-icon>
        Hidden Columns ({{ invoiceHiddenColumns.length }})
      </v-btn>
    </template>

    <v-card class="card-content">
      <v-list class="d-flex active-filter-field justify-center">
        <v-chip
          v-for="(filter, index) in invoiceHiddenColumns"
          :key="index"
          close
          @click:close="removeFilter(filter)"
          class="chips"
        >
          {{ filter.text }}
        </v-chip>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions class="justify-center">
        <v-btn color="#ff4242" text @click="removeFilter('all')"> Clear All </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters('invoice', [
      'invoiceFilters',
      'invoiceArrayFilters',
      'isInvoiceFilterDisabled',
      'invoiceColumns',
      'invoiceHiddenColumns',
    ]),
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('user', ['me']),
  },
  beforeMount() {
    // default to show expand columns
    this.removeFilter({text:''});
  },
  watch: {
    invoiceColumns() {
      if (!this.invoiceHiddenColumns.length) this.showMenu = false;

      this.filtersChanged = true;

      setTimeout(() => {
        this.filtersChanged = false;
      }, 500);
    },
  },
  data() {
    return {
      showMenu: false,
      filtersChanged: false,
      statusFilters: ['Pending', 'Accepted', 'Declined', 'Canceled'],
      leaveDateFilters: ['Date Range', 'Today', 'Upcoming'],
    };
  },
  methods: {
    ...mapActions('invoice', ['getInvoices']),
    ...mapMutations('invoice', ['setInvoiceFilters', 'setColumns', 'setUserPreference']),
    removeFilter(filter) {
      const columns = cloneDeep(this.invoiceColumns);

      for (let column of columns) {
        if (filter === 'all' || column.text === filter.text) {
          column.show = true;
        }
      }
      this.setColumns({ columns, userId: this.me.id });
    },
  },
};
</script>
<style scoped>
.card-content {
  max-width: 350px;
}
.active-filter-field {
  max-width: 350px;

  flex-wrap: wrap;
}
::v-deep .chips {
  margin: 5px;
  border: 2px solid #59c129;
}
::v-deep .chips .v-chip:not(.v-chip--active) {
  background: #f5f5f5;
}
::v-deep .chips .v-chip__close {
  color: #ff4242;
}
.flash {
  animation: flashAnimation 0.5s ease-in-out;
}
@keyframes flashAnimation {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
</style>
